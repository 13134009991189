import { render, staticRenderFns } from "./registerdone.vue?vue&type=template&id=40e24ada&"
import script from "./registerdone.vue?vue&type=script&lang=js&"
export * from "./registerdone.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {BreadcrumbItem: require('/tmp/build_2a97698b/components/breadcrumb/BreadcrumbItem.vue').default,Breadcrumb: require('/tmp/build_2a97698b/components/breadcrumb/Breadcrumb.vue').default,Link: require('/tmp/build_2a97698b/components/link/Link.vue').default,Button: require('/tmp/build_2a97698b/components/button/Button.vue').default,Article: require('/tmp/build_2a97698b/components/article/Article.vue').default,Sidebar: require('/tmp/build_2a97698b/components/Sidebar.vue').default,PageWrap: require('/tmp/build_2a97698b/components/PageWrap.vue').default})
