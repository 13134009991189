import { render, staticRenderFns } from "./page.vue?vue&type=template&id=6cf15162&"
import script from "./page.vue?vue&type=script&lang=js&"
export * from "./page.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Header: require('/tmp/build_2a97698b/components/Header.vue').default,Sidebar: require('/tmp/build_2a97698b/components/Sidebar.vue').default,PageWrap: require('/tmp/build_2a97698b/components/PageWrap.vue').default,Footer: require('/tmp/build_2a97698b/components/Footer.vue').default,LayoutWrap: require('/tmp/build_2a97698b/components/LayoutWrap.vue').default})
