import { render, staticRenderFns } from "./index.vue?vue&type=template&id=5becc656&"
import script from "./index.vue?vue&type=script&lang=js&"
export * from "./index.vue?vue&type=script&lang=js&"
import style0 from "./index.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {BreadcrumbItem: require('/tmp/build_2a97698b/components/breadcrumb/BreadcrumbItem.vue').default,Breadcrumb: require('/tmp/build_2a97698b/components/breadcrumb/Breadcrumb.vue').default,ListHeaderTitle: require('/tmp/build_2a97698b/components/list/ListHeaderTitle.vue').default,Pagination: require('/tmp/build_2a97698b/components/pagination/Pagination.vue').default,ListSerial: require('/tmp/build_2a97698b/components/list/ListSerial.vue').default})
