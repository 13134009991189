import { render, staticRenderFns } from "./_id.vue?vue&type=template&id=9bdb2bf2&"
import script from "./_id.vue?vue&type=script&lang=js&"
export * from "./_id.vue?vue&type=script&lang=js&"
import style0 from "./_id.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {BreadcrumbItem: require('/tmp/build_2a97698b/components/breadcrumb/BreadcrumbItem.vue').default,Breadcrumb: require('/tmp/build_2a97698b/components/breadcrumb/Breadcrumb.vue').default,ListSerialDetail: require('/tmp/build_2a97698b/components/list/ListSerialDetail.vue').default,Heading: require('/tmp/build_2a97698b/components/heading/Heading.vue').default,ListSerial: require('/tmp/build_2a97698b/components/list/ListSerial.vue').default,Article: require('/tmp/build_2a97698b/components/article/Article.vue').default})
