import { render, staticRenderFns } from "./user.vue?vue&type=template&id=58e8691e&"
import script from "./user.vue?vue&type=script&lang=js&"
export * from "./user.vue?vue&type=script&lang=js&"
import style0 from "./user.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {BreadcrumbItem: require('/tmp/build_2a97698b/components/breadcrumb/BreadcrumbItem.vue').default,Breadcrumb: require('/tmp/build_2a97698b/components/breadcrumb/Breadcrumb.vue').default,Button: require('/tmp/build_2a97698b/components/button/Button.vue').default,Form: require('/tmp/build_2a97698b/components/form/Form.vue').default,Article: require('/tmp/build_2a97698b/components/article/Article.vue').default,Sidebar: require('/tmp/build_2a97698b/components/Sidebar.vue').default,PageWrap: require('/tmp/build_2a97698b/components/PageWrap.vue').default})
